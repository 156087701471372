<template>
    <div>
        <b-modal id="edit-modal" hide-footer size="md" centered title="Edit Plan" @hidden="resetModal">
            <div class="modal_content">
                <b-alert dismissible :show="hasError" class="p-1" variant="danger" @dismissed="clearErrorMsg">{{ errorMsg }}</b-alert>

                <p class="mb-0 ">Plan Name</p>
                <b-form-input class="mb-1" v-model="record.name" placeholder="Plan name" type="text" />

                <p class="mb-0 ">Billing Cycle</p>
                <v-select 
                        class="w-100 mb-1" 
                        v-model="record.billing_cycle" 
                        placeholder="Select Billing Cycle" 
                        :clearable="true" 
                        :reduce="(option) => option.name" 
                        :options="get_billing_cycles" 
                        label="label"
                    >
                    <template v-slot:option="option">
                        {{ option.label }}
                    </template>
                </v-select>

                <div class="row">
                    <div class="col-md-6">
                        <p class="mb-0">Currency</p>
                        <v-select
                                class="w-100 mb-1"
                                v-model="record.currency"
                                placeholder="Select Currency"
                                :clearable="false"
                                :reduce="(option) => option"
                                :options="get_currencies"
                                label="name"
                                @input="selectCurrency"
                            >
                            <template v-slot:option="option">
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>
                    <div class="col-md-6">
                        <p class="mb-0 ">Price</p>
                        <div class="input-setting mb-1">
                            <b-input-group size="lg" :prepend="record.currency_symbol" :append="record.currency_code">
                                <b-form-input v-model="record.price" placeholder="Price" type="text" />
                            </b-input-group>
                        </div>
                    </div>
                </div>

                <p class="mb-0 ">Country</p>
                <v-select 
                        class="w-100 mb-1" 
                        v-model="record.country_code" 
                        placeholder="Select Country" 
                        :clearable="true" 
                        :reduce="(option) => option.code" 
                        :options="get_countries"
                        label="name"
                    >
                    <template v-slot:option="option">
                        {{ option.name }}
                    </template>
                </v-select>

                <div class="modal-footer px-0">
                    <b-button @click="saveBtn" :disabled="loading" variant="primary">
                        <b-spinner v-if="loading" small />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../Helpers';
export default {
    components: {
        BButton,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
        edit_plan: function (newVal) {
            this.record = this.edit_plan;
        },
    },
    methods: {
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        selectCurrency(item){
            console.log('selected currency=', item)
            this.record.currency_id = item._id
            this.record.currency_symbol = item.symbol
            this.record.currency_code = item.code
        },
        async saveBtn() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            try {
                const resp = await axios.put(process.env.VUE_APP_API_URL + "/billing/plans/update",
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitPlanUpdated');
                this.$bvModal.hide("edit-modal");
                this.loading = false;
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.name) {
                this.errorMsg = "Plan name field id required."
                return;
            }
            if (!this.record.billing_cycle) {
                this.errorMsg = "Billing cycle field id required."
                return;
            }
            if (!this.record.currency_id) {
                this.errorMsg = "Currency field id required."
                return;
            }
            if (!this.record.price) {
                this.errorMsg = "Price field id required."
                return;
            }
            if (!this.record.country_code) {
                this.errorMsg = "Country field id required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "get_billing_cycles",
        "get_billing_plan_types",
        "get_currencies",
        "get_countries",
        "edit_plan"
    ],
};
</script>
